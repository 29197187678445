<template>
  <b-card no-body>
    <roles-form
      :visible="showForm"
      :resource-id="resourceId"
      @saved="fetchList()"
      @close="onCloseForm"
    />
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-button
            id="create-button"
            variant="primary"
            :class="{
              'icon-disabled': !hasSystemSettingsActions,
            }"
            @click="hasSystemSettingsActions && create()"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Create New') }}</span>
          </b-button>
          <b-tooltip target="create-button">
            {{ hasSystemSettingsActions ? $t('Create') : $t('no-action-permission') }}
          </b-tooltip>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex justify-content-end">
            <div>
              <prozess-search-input
                :value="search"
                :placeholder="$t('Search')"
                @input="handleSearch"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="m-2">
      <b-table
        ref="refTable"
        :busy="loading"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
        class="table-responsive"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head()="{ label }">
          <span>{{ $t(label) }}</span>
        </template>

        <template #cell(roleName)="{ item }">
          <span class="text-nowrap">
            {{ item.name }}
          </span>
        </template>

        <template #cell(permissions)="{ item }">
          <template v-for="(key, index) in Object.keys(displayPermissions(item.permissions))">
            <b-badge
              v-if="index < showPermission"
              :key="key"
              pill
              variant="secondary"
              class="mr-1"
            >
              {{ $t($case.title(key)) }}:
              {{
                displayPermissions(item.permissions)
                  [key].map(item => $t(item.operation))
                  .join(', ')
              }}
            </b-badge>
          </template>
          <b-badge
            v-if="Object.keys(displayPermissions(item.permissions)).length - showPermission > 0"
            pill
            class="mr-1"
            variant="secondary"
          >
            +{{ Object.keys(displayPermissions(item.permissions)).length - showPermission }}
          </b-badge>
        </template>

        <template #cell(users)="{ item }">
          <p class="text-nowrap text-center m-0">
            {{ item.userCount }}
          </p>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="{ item }">
          <div class="text-nowrap">
            <feather-icon
              :id="`edit-row-${item.id}-preview-icon`"
              icon="EditIcon"
              size="16"
              :class="{
                'icon-disabled': !hasSystemSettingsActions,
              }"
              class="mx-1 cursor-pointer"
              @click="hasSystemSettingsActions && edit(item.id)"
            />
            <b-tooltip
              :title="!hasSystemSettingsActions ? $t('no-action-permission') : $t('Edit')"
              :target="`edit-row-${item.id}-preview-icon`"
              placement="left"
            />
            <feather-icon
              :id="`delete-row-${item.id}-preview-icon`"
              icon="TrashIcon"
              size="16"
              :class="{
                'icon-disabled': !hasSystemSettingsActions,
              }"
              class="mx-1 cursor-pointer"
              @click="hasSystemSettingsActions && remove(item.id)"
            />
            <b-tooltip
              :title="!hasSystemSettingsActions ? $t('no-action-permission') : $t('Delete')"
              :target="`delete-row-${item.id}-preview-icon`"
              placement="left"
            />
          </div>
        </template>
      </b-table>
    </div>
    <prozess-pagination
      v-if="!loading"
      class="mx-2 mb-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      @change="handleCurrentPage"
    />
  </b-card>
</template>

<script>
import { swalConfirmDeleteOption } from '@/helpers/app'
import roleService from '@/services/role'
import listMixinFactory from '@/mixins/list'
import RolesForm from './Form.vue'

export default {
  components: {
    RolesForm,
  },
  mixins: [
    listMixinFactory({
      routeName: 'roles-settings',
      service: roleService,
      tableColumns: [
        { key: 'roleName', label: 'Role Name', sortable: true },
        { key: 'permissions', class: 'disabled' },
        { key: 'users', class: 'text-center disabled' },
        { key: 'actions', class: 'text-center disabled' },
      ],
      searchField: 'name',
      sort: {
        id: 'id',
        roleName: 'name',
      },
    }),
  ],
  data() {
    return {
      showPermission: 2,
      resourceId: null,
    }
  },

  methods: {
    displayPermissions(permissions) {
      return this.$lodash.groupBy(permissions, 'name')
    },
    create() {
      this.resourceId = null
      this.showForm = true
    },
    edit(id) {
      this.resourceId = id
      this.showForm = true
    },
    remove(id) {
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(result => {
        if (result.value) {
          this.deleteResource(id)
        }
      })
    },
    onCloseForm() {
      this.resourceId = null
      this.showForm = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
