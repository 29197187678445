<template>
  <div>
    <b-modal
      :id="modal"
      :ref="modal"
      :visible="visible"
      hide-footer
      centered
      no-fade
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      :title="this.$t(modalTitle)"
    >
      <b-overlay
        :show="resourceId && loading"
        spinner-variant="primary"
        rounded="sm"
        variant="transparent"
      >
        <b-card-text>
          <form
            autocomplete="off"
            @submit.prevent="save"
          >
            <b-container>
              <b-row>
                <b-col
                  md="6"
                  class="p-0"
                >
                  <b-form-group
                    :label="$t('Name')"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="roleName"
                      :state="null"
                      :placeholder="$t('Name')"
                    />
                    <span
                      v-if="$hasError('name')"
                      class="invalid-feedback"
                    >
                      {{ $t($hasError('name')) }}
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
            <div class="role-form">
              <b-tabs>
                <b-tab
                  v-for="group in Object.keys(groupedPermissions)"
                  :key="group"
                  :title="$t(group)"
                >
                  <div
                    v-if="rows && actions"
                    class="table-responsive"
                    style="height: 250px; overflow-y: auto"
                  >
                    <table class="table rounded-table">
                      <thead>
                        <th>
                          <b-form-checkbox
                            class="custom-control-primary"
                            @change="selectAll($event, $lodash.groupBy(groupedPermissions[group], 'name'), group)"
                          >
                            {{ $t('Select All') }}
                          </b-form-checkbox>
                        </th>
                        <th
                          v-for="key in group !== 'Document' ? Object.keys(actions[group]).filter(val => val !== 'General_Read') : Object.keys(actions[group])"
                          :key="key"
                        >
                          <b-form-checkbox
                            v-model="actions[group][key]"
                            class="custom-control-primary"
                            @change="toggleByOperation($event, key, $lodash.groupBy(groupedPermissions[group], 'name'))"
                          >
                            {{ $t(key) }}
                          </b-form-checkbox>
                        </th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="name in Object.keys($lodash.groupBy(groupedPermissions[group], 'name')).sort((a, b) => getPermissionName(a).localeCompare(getPermissionName(b)))"
                          :key="name"
                        >
                          <td>
                            <b-form-checkbox
                              v-model="rows[name]"
                              class="custom-control-primary"
                              @change="toggleByRow($event, name, $lodash.groupBy(groupedPermissions[group], 'name'))"
                            >
                              {{ getPermissionName(name) }}
                            </b-form-checkbox>
                          </td>
                          <td
                            v-for="operation in group !== 'Document'
                              ? Object.keys(actions[group]).filter(val => val !== 'General_Read')
                              : Object.keys(actions[group])"
                            :key="operation"
                          >
                            <template v-for="(data, index) in $lodash.groupBy(groupedPermissions[group], 'name')[name]">
                              <b-form-checkbox
                                v-if="operation === data.operation"
                                :key="index + name + '_' + operation"
                                v-model="checkboxValues[name + '-' + operation + '-' + data.id]"
                                class="custom-control-primary"
                                @change="handleCheckboxChange($event, data)"
                              />
                            </template>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
            <div class="d-flex justify-content-end">
              <b-button
                class="mr-1"
                variant="primary"
                :disabled="saving"
                type="submit"
              >
                <template>
                  <feather-icon
                    v-if="!saving"
                    icon="SaveIcon"
                  />
                  <b-spinner
                    v-else
                    small
                  />
                </template>
                <span class="align-middle ml-1">{{ $t(saveButtonLabel) }}</span>
              </b-button>
              <b-button
                variant="btn btn-outline-primary"
                @click="close"
              >
                <span class="align-middle">{{ $t('Cancel') }}</span>
              </b-button>
            </div>
          </form>
        </b-card-text>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import securityService from '@/services/security'
import roleService from '@/services/role'

export default {
  props: {
    resourceId: {
      type: [String, Number],
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      modal: 'rolesForm',
      permissions: [],
      groupedPermissions: [],
      checkboxValues: {},
      roleName: '',
      saving: false,
      rows: null,
      actions: null,
      errors: [],
      showForm: false,
    }
  },
  computed: {
    saveButtonLabel() {
      return this.resourceId ? 'Save Changes' : 'Save'
    },
    modalTitle() {
      return this.resourceId ? 'Edit Role' : 'New Role'
    },
  },
  watch: {
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          if (this.resourceId) this.getOne(this.resourceId)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getPermissions()
  },
  methods: {
    getPermissionName(name) {
      let value = name
      if (value === 'Settings') {
        value = `Company ${name}`
      } else {
        value = value.split('__').join('')
        value = this.$case.title(value)
      }
      return this.$t(value)
    },
    handleCheckboxChange(value, { operation, name, id, grouping }) {
      // Auto unselect all permissions if Read is false
      if (operation === 'Read' && value === false) {
        this.turnOffAllPermission(name)
      }
      // Uncheck row or column checkbox because one permission is not selected anymore
      if (value === false) {
        this.actions[grouping][operation] = false
        this.rows[name] = false
      }
      // Auto select Read permission if Create, Update, Delete is checked
      if (name !== 'Document') {
        if (operation !== 'Read' && value === true) {
          const permission = this.permissions.find(item => item.name === name && item.operation === 'Read')
          this.checkboxValues[`${name}-Read-${permission.id}`] = true
        }
      }
      if (name === 'Document') {
        if (operation === 'General_Read' && value === true) {
          const permission = this.permissions.find(item => item.name === name && item.operation === 'Read')
          this.checkboxValues[`${name}-Read-${permission.id}`] = false
        }
        if (operation === 'Read' && value === true) {
          const permission = this.permissions.find(item => item.name === name && item.operation === 'General_Read')
          this.checkboxValues[`${name}-General_Read-${permission.id}`] = false
        }
      }

      this.$forceUpdate()
    },
    getOne(id) {
      this.loading = true
      roleService
        .getOne(id)
        .then(res => {
          this.roleName = res.data.name
          const values = {}
          res.data.permissions.forEach(({ name, operation, id: _id, forCustomEntity }) => {
            values[`${forCustomEntity ? '__' : ''}${name}-${operation}-${_id}`] = true
          })
          this.checkboxValues = values
          this.$forceUpdate()
        })
        .finally(() => {
          this.loading = false
        })
    },
    reset() {
      this.errors = []
      this.roleName = ''
      this.loadInitialData()
      this.checkboxValues = {}
    },
    save() {
      this.saving = true

      const { permissionIds, customEntitiesPermissionIds } = Object.keys(this.checkboxValues).reduce(
        (acc, key) => {
          if (this.checkboxValues[key] === false) return acc

          if (key.includes('__')) {
            // custom entity
            acc.customEntitiesPermissionIds.push(key.split('-')[2])
          } else {
            acc.permissionIds.push(key.split('-')[2])
          }

          return acc
        },
        {
          permissionIds: [],
          customEntitiesPermissionIds: [],
        },
      )

      const name = this.roleName.trim()

      const data = {
        name,
        permissionIds,
        customEntitiesPermissionIds,
      }

      this.createOrUpdate(data)
        .then(res => {
          this.$emit('saved')
          this.close()
        })
        .catch(err => {
          this.errors = this.$lodash.get(err, 'response.data.errors', [])
        })
        .finally(() => {
          this.saving = false
        })
    },
    createOrUpdate(data) {
      return this.resourceId ? roleService.update(this.resourceId, data) : roleService.store(data)
    },
    selectAll(value, row, group) {
      // console.log(value, row, group)
      this.permissions
        .filter(item => item.grouping === group)
        .forEach(permission => {
          if (permission.operation !== 'General_Read') {
            this.rows[permission.name] = value
          }
        })
      Object.keys(this.actions[group]).forEach(action => {
        if (action !== 'General_Read') {
          this.actions[group][action] = value
        }
      })
      Object.keys(row).forEach(name => {
        row[name].forEach(permission => {
          if (permission.operation !== 'General_Read') {
            this.checkboxValues[`${name}-${permission.operation}-${permission.id}`] = value
          }
        })
      })
      this.$forceUpdate()
    },
    toggleByRow(value, name, row) {
      row[name].forEach(item => {
        if (value === false) {
          this.checkboxValues[`${name}-${item.operation}-${item.id}`] = value
        }
        if (item.operation !== 'General_Read') {
          this.checkboxValues[`${name}-${item.operation}-${item.id}`] = value
        }
      })
      this.$forceUpdate()
    },
    turnOffAllPermission(name) {
      this.permissions
        .filter(permission => permission.name === name && permission.operation !== 'Read')
        .forEach(permission => {
          this.checkboxValues[`${name}-${permission.operation}-${permission.id}`] = false
        })
    },
    toggleByOperation(value, operation, row) {
      Object.keys(row).forEach(name => {
        row[name].forEach(item => {
          if (item.operation === operation) {
            this.checkboxValues[`${name}-${operation}-${item.id}`] = value
          }
          if (operation !== 'Read' && value === true) {
            this.actions[item.grouping].Read = true // column header checkbox
            if (item.operation === 'Read') {
              // only toggle ID Read Permission
              this.checkboxValues[`${name}-Read-${item.id}`] = true
            }
          }
          if (operation === 'General_Read' && value === true) {
            this.actions[item.grouping].Read = false // column header checkbox
            if (item.operation === 'Read') {
              // only toggle ID Read Permission
              this.checkboxValues[`${name}-Read-${item.id}`] = false
            }
          }
          if (operation === 'Read' && value === true) {
            this.actions[item.grouping].General_Read = false // column header checkbox
            if (item.operation === 'General_Read') {
              // only toggle ID Read Permission
              this.checkboxValues[`${name}-General_Read-${item.id}`] = false
            }
          }
          if (operation === 'Read' && value === false) {
            this.turnOffAllPermission(name)
          }
        })
      })
      this.$forceUpdate()
    },
    loadInitialData() {
      this.rows = {}
      this.actions = {}
      Object.keys(this.groupedPermissions).forEach(group => {
        this.actions[group] = {
          Create: false,
          Read: false,
          General_Read: false,
          Update: false,
          Delete: false,
        }
      })
      Object.keys(this.$lodash.groupBy(this.permissions, 'name')).forEach(name => {
        this.rows[name] = false
      })
    },
    getPermissions() {
      securityService
        .getPermissions({
          size: 200, // max 200 according to sir rob
        })
        .then(res => {
          this.permissions = res.data.pageItems.map(item => {
            if (item.forCustomEntity) {
              item.name = `__${item.name}` // custom entity
            }
            return item
          })
          this.groupedPermissions = this.$lodash.groupBy(this.permissions, 'grouping')
          this.loadInitialData()
        })
    },
    close() {
      this.reset()
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/role-form.scss';
</style>
