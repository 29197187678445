import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/iam'

export default {
  getPermissions(payload) {
    return API.get(`${API_URL}/combined_permissions?${toQuery(payload)}`)
  },
}
